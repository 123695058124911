import { Component, Mixins, Ref, Vue } from 'vue-property-decorator'

import ProjectTable from '@/components/ProjectTable.vue'
import LocaleMixin from '@/mixins/LocaleMixin'
import MobileMixin from '@/mixins/MobileMixin'
import PlatformSettingsMixin from '@/mixins/PlatformSettingsMixin'
import TableSettingsMixin from '@/mixins/TableSettingsMixin'
import ToastMixin from '@/mixins/ToastMixin'
import UserMixin from '@/mixins/UserMixin'
import { IAPIFilterCtx } from '@/types/base'
import { IFilter } from '@/types/filters'
import { IMinimalProject, IProject } from '@/types/projects'
import { API_URLS } from '@/utils/helpers'

@Component({
  name: 'project-list-mixin',
  mixins: [MobileMixin, UserMixin, TableSettingsMixin, ToastMixin, LocaleMixin, PlatformSettingsMixin],
})
export default class ProjectListMixin extends Mixins(
  Vue,
  MobileMixin,
  UserMixin,
  TableSettingsMixin,
  ToastMixin,
  LocaleMixin,
  PlatformSettingsMixin
) {
  @Ref() readonly projectTable!: ProjectTable
  currentPage = 1
  pageSize = 25
  searchString = ''
  hoveredRowId = 0
  totalItemsCount = 0
  infoModalOpen = false
  exportModalIsOpen = false
  duplicateProjectModalOpen = false
  activeTableFilters: IFilter[] | null = null
  selectedProject: IProject = null
  selectedProjects: (IProject | IMinimalProject)[] = []

  get apiContext(): IAPIFilterCtx {
    return {
      search: this.searchString,
      filters: this.activeTableFilters,
      archived: false,
      xlsx: true,
    }
  }

  get exportUrl(): string {
    return API_URLS.PROJECTS.EXPORT(this.apiContext)
  }

  get asyncExportUrl(): string {
    return API_URLS.PROJECTS.ASYNC_EXPORT(this.apiContext)
  }

  formatDate(dateString: string): string {
    return this.$moment(dateString).format('LLL')
  }

  tableFilterUpdated(filters: IFilter[]): void {
    this.activeTableFilters = filters
  }

  handleSearchChange(event) {
    this.searchString = event.target.value
  }

  duplicateSuccess(): void {
    this.duplicateProjectModalOpen = false
    this.projectTable.loadProjects()
  }

  duplicateFailed(): void {
    this.duplicateProjectModalOpen = false
  }

  openInfoModal(project: IProject): void {
    this.selectedProject = project
    this.infoModalOpen = true
  }

  openDuplicateProjectModal(project?: IProject): void {
    project ? (this.selectedProject = project) : (this.selectedProject = null)
    this.duplicateProjectModalOpen = true
  }
}
