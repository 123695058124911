import axios from 'axios'
import { Component, Mixins, Prop, Vue, Ref, Watch } from 'vue-property-decorator'

import PersistentFiltersMixin from '@/mixins/PersistentFiltersMixin'
import LocaleMixin from '@/mixins/LocaleMixin'

import { IAPIFilterCtx, ITableSettings } from '@/types/base'
import { BvComponent, BvTableCtxObject } from 'bootstrap-vue'
import { IFilter, IOrder } from '@/types/filters'
import { IMinimalProject, IProject } from '@/types/projects'
import { addContextToUrl, API_URLS } from '@/utils/helpers'
import { BvTableFieldArrayWithStickColumn } from '@/types/base'
import { IFilters } from '@/types/filters'
import { reviewStatusOptions, progressStatusOptions } from '@/utils/filterOptions'

@Component({
  mixins: [PersistentFiltersMixin, LocaleMixin],
  name: 'project-list-mixin',
})
export default class ProjectListMixin extends Mixins(Vue, PersistentFiltersMixin, LocaleMixin) {
  @Ref() readonly selectableTable!: BvComponent

  @Prop({ default: false }) archived!: boolean
  @Prop() searchString!: string
  @Prop() tableSettings!: ITableSettings | null
  @Prop() pageSize!: number
  @Prop() selectedProjects!: (IProject | IMinimalProject)[]
  projects: IProject[] = []
  projectCount = 0
  hoveredRowId = 0
  currentPage = 1
  tableLoading = false
  orderObject: IOrder = null
  tableContext: BvTableCtxObject | null = null

  filterCols: { field: string; filter: string }[] = [
    { field: 'status', filter: 'projectStatusFilter' },
    { field: 'review_status', filter: 'reviewStatusFilter' },
    { field: 'organization_status', filter: 'organizationStatusFilter' },
  ]

  filters: IFilters = {
    projectStatusFilter: {
      filterName: 'status_named',
      selected: [],
      options: progressStatusOptions(this.$language.current),
    },
    organizationStatusFilter: {
      filterName: 'organization_status_named',
      selected: [],
      options: {
        public: this.$gettext('Public'),
        rejected: this.$gettext('Rejected'),
        accepted: this.$gettext('Accepted'),
        needs_review: this.$gettext('Needs review'),
        noe_expired: this.$gettext('Proof expired'),
      },
    },
    reviewStatusFilter: {
      filterName: 'review_status_named',
      selected: [],
      options: reviewStatusOptions(this.$language.current),
    },
  }

  @Watch('pageSize')
  onPageSizeChanged(): void {
    this.loadProjects()
  }

  @Watch('currentPage')
  onCurrentPageChanged(): void {
    this.loadProjects()
  }

  @Watch('searchString')
  onSearchStringChanged(): void {
    this.loadProjects()
  }

  get emptyText(): string {
    return this.$gettext('No projects found')
  }

  get searchPlaceholder(): string {
    return this.$gettext('Search projects')
  }

  get sortDesc(): boolean | undefined {
    if (this.orderObject) {
      return this.orderObject.sortDesc
    }
    return this.tableContext ? this.tableContext.sortDesc : undefined
  }

  get sortBy(): string | undefined {
    if (this.orderObject) {
      return this.orderObject.sortBy
    }
    return this.tableContext ? this.tableContext.sortBy : undefined
  }

  get urlContext(): IAPIFilterCtx {
    return {
      sortBy: this.sortBy,
      sortDesc: this.sortDesc,
      search: this.searchString,
      filters: this.activeFilters,
      page: this.currentPage,
      pageSize: this.pageSize,
      archived: this.archived,
    }
  }

  get allProjectsAPIURL() {
    return addContextToUrl(API_URLS.PROJECTS.MINIMAL_LIST, this.urlContext)
  }

  get availableFilterCols(): { field: string; filter: string }[] {
    if (this.tableSettings) {
      const fieldKeys = Object.keys(this.tableSettings.table_fields)
      return this.filterCols.filter((filterCol) => {
        return (
          this.tableSettings &&
          !this.tableSettings.hidden_fields.includes(filterCol.field) &&
          fieldKeys.includes(filterCol.field)
        )
      })
    }
    return []
  }

  get allVisibleItemsSelected(): boolean {
    return this.projects.length <= this.selectedProjects.length
  }

  get availableFields(): BvTableFieldArrayWithStickColumn {
    const _availableFields = [...this.fields]
    if (this.tableSettings) {
      const fieldKeys = Object.keys(this.tableSettings.table_fields)
      const additionalFields = this.tableSettings.table_fields.additional_fields
      if (additionalFields) {
        for (const additionalStepKey of Object.keys(additionalFields)) {
          const additionalStep = this.tableSettings.table_fields.additional_fields[additionalStepKey]
          for (const additionalFieldKey of Object.keys(additionalStep)) {
            fieldKeys.push(additionalFieldKey)
          }
        }
      }
      const displayedFields = fieldKeys.filter(
        (field) => this.tableSettings && !this.tableSettings.hidden_fields.includes(field)
      )
      displayedFields.unshift('selected', 'index')
      _availableFields.unshift(
        { key: 'selected', label: 'Select All', sortable: false },
        { key: 'index', label: this.$gettext('#') }
      )
      return _availableFields.filter((field) => {
        const fieldName = typeof field === 'string' ? field : field.key
        return displayedFields.includes(fieldName)
      })
    }
    return []
  }

  async loadProjects(): Promise<void> {
    this.tableLoading = true
    await axios.get(addContextToUrl(API_URLS.PROJECTS.LIST, this.urlContext)).then((response) => {
      this.projects = response.data.results
      this.projectCount = response.data.count
      this.$emit('total-count-update', this.projectCount)
    })
    this.tableLoading = false
  }

  async loadInitialProjects(): Promise<void> {
    this.$wait.start('fetch projects')
    await Promise.all([this.loadProjects()])
    if (this.projects.length > 0 && Object.prototype.hasOwnProperty.call(this.projects[0], 'content_review_status')) {
      this.filters.organizationStatusFilter.options = Object.assign(this.filters.organizationStatusFilter.options, {
        content_accepted: this.$gettext('Content accepted'),
        content_rejected: this.$gettext('Content rejected'),
        nonprofit: this.$gettext('nonprofit'),
        not_nonprofit: this.$gettext('Not nonprofit'),
      })
    }
    this.$wait.end('fetch projects')
  }

  async reloadProjects(): Promise<void> {
    this.tableLoading = true
    this.currentPage = 1
    this.loadProjects()
  }

  getProjectCol(project: IProject): string {
    return `<a href="/site-admin/project/${project.slug}/update/">${project.title}</a>`
  }

  getOrganizationCol(project: IProject): string {
    return `<a href="/site-admin/organization/${project.organization.slug}/update/">${project.organization.title}</a>`
  }

  applyFilterAndReload(filter: IFilter): void {
    this.applyFilter(filter)
    this.reloadProjects()
    this.$emit('updateOrganizationOption')
  }

  applyOrderAndReload(orderObject: IOrder): void {
    this.orderObject = orderObject
    this.reloadProjects()
  }

  resetFiltersAndReload(): void {
    this.resetFilters()
    this.reloadProjects()
    this.$emit('updateOrganizationOption')
  }

  sortingChanged(ctx: BvTableCtxObject): void {
    this.tableContext = ctx
    this.orderObject = null
    this.loadProjects()
  }

  rowHovered(row: IProject): void {
    if (row.id) {
      this.hoveredRowId = row.id
    }
  }

  toggleCheckAll(value: boolean | string): void {
    if (value) {
      this.selectableTable.selectAllRows()
    } else {
      this.selectableTable.clearSelected()
    }
  }

  onRowSelected(selectedItems: IProject[]): void {
    this.$emit('update:selectedProjects', selectedItems)
  }

  toggleSelect(rowIndex: number): void {
    if (this.selectableTable.isRowSelected(rowIndex)) {
      this.selectableTable.unselectRow(rowIndex)
    } else {
      this.selectableTable.selectRow(rowIndex)
    }
  }
}
